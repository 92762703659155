import { action, toJS } from 'mobx';
import { Supplier as BaseSupplier } from '@link/gds-share/stores';
import { supplierApi } from '@link/gds-admin/services';
import { Supplier as ISupplier } from '@link/gds-share/services/types';
import { SUPPLIER_LIST } from '@link/gds-share/const';

export class Supplier extends BaseSupplier {
  @action
  async fetchSuppliers() {
    const response = await supplierApi.listSuppliers({
      body: {
        cursor: this.nextCursor
      }
    });
    const { suppliers, nextCursor = '' } = response;
    if (suppliers) {
      this.addSuppliers(suppliers);
    }
    this.setCursor(nextCursor);
    if (nextCursor === '') {
      this.loading = false;
      localStorage.setItem(SUPPLIER_LIST, JSON.stringify(this.suppliers));
    }
    return response;
  }

  @action
  async fetchAllSuppliers() {
    if (!this.loaded) {
      this.loading = true;
      const { nextCursor } = await this.fetchSuppliers();
      if (nextCursor) {
        await this.fetchAllSuppliers();
      }
    }
  }

  @action
  async getSupplierInfo() {
    if (!this.currentSupplierInfo) {
      const supplier = await supplierApi.getSupplier({
        body: {
          supplierId: ''
        }
      });
      this.setSupplierInfo(supplier);
      return supplier;
    }
  }

  @action
  async getActivitySupplier(supplierId: string) {
    if (this.currentActivitySupplier?.id === supplierId) {
      return toJS(this.currentActivitySupplier);
    } else {
      const supplier = await supplierApi.getSupplier({
        body: {
          supplierId
        }
      });
      this.setCurrentActivitySupplier(supplier);
      return supplier;
    }
  }

  @action
  setCurrentActivitySupplier(supplier: ISupplier) {
    this.currentActivitySupplier = supplier;
  }
}
export default new Supplier();
