// Generated i18n file
export default {
  common: {
    delete: '削除',
    updateSuccess: '更新しました',
    sync: 'Sync'
  },
  confirmDelete: {
    title: 'ご確認ください',
    prompt: '本当に削除してもよろしいですか？'
  },
  supplierSelect: {
    selectSupplier: '催行会社を選択してください'
  },
  agentGroup: {
    addAgent: '代理店追加',
    deleteAgent: '代理店削除'
  }
};
